import React, { Component } from "react"
import Categories from "../components/categories"
import Modal from "react-bootstrap/Modal"
import { FaRegCircle, FaCheckCircle } from "react-icons/fa"
import "bootstrap/dist/css/bootstrap.css"
import "../components/skeinetStyles.css"
import "../components/layout.css"
import { loadDishes } from "../utils/functions"

class Menu extends Component {
  state = {
    menu: [],
    open: false,
    productSelected: {},
    categorySelected: { id: 1 },
  }

  componentDidMount = async () => {
    await loadDishes(this.state.categorySelected)
  }

  handleClickItem = item => {
    const { menu } = this.state
    for (let i = 0; i < menu.length; i++) {
      if (menu[i].id === item.id) {
        this.setState({ productSelected: item, open: true })
      }
    }
    this.setState({ menu })
  }

  handleHide = () => {
    this.setState({ open: false })
  }

  handleShow = () => {
    this.setState({ open: true })
  }

  handleChangeCategory = async categorySelected => {
    const menu = await loadDishes(categorySelected)
    this.setState({ menu, categorySelected })
  }

  handleReset = async () => {
    await loadDishes(this.state.categorySelected)
  }

  render() {
    const { productSelected, menu, categorySelected } = this.state
    return (
      <div className="row m-0 h-100">
        <div className="leftSideMenuArea">
          <Categories
            categorySelected={categorySelected}
            onChange={this.handleChangeCategory}
            onReset={this.handleReset}
          />
        </div>
        <div className="foodBackgroundBorder foodArea foodBackground">
          <div className="row  m-0">
            {menu && menu.map(
              item =>
                item.category === categorySelected && (
                  <div
                    key={item.id}
                    className={
                      item.selected === true
                        ? "bg-selected col-4 py-2"
                        : "col-4 px-2 py-2"
                    }
                    onClick={() => this.handleClickItem(item)}
                  >
                    <img
                      className="mb-0 plateBorder"
                      src={
                        "https://www.skeinet.com/fakeFogher/" + item.id + ".jpg"
                      }
                      alt="foot thumb "
                    />
                    <div className="foodLabel">
                      <p className="m-0 b" style={{ fontSize: "0.9em" }}>
                        {item.label}
                      </p>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Menu
